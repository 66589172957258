import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  InputGroup,
  HTMLSelect,
  FormGroup,
  Icon,
  Intent,
} from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";
import PhoneInput from "react-phone-number-input";

import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

const Helper = () => <span>Please provide a valid phone number</span>;

export const PhoneNumberPrompt = ({
  updateResponse,
  hasError,
  validPhoneNumber,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const Helper = ({ visible }) => (
    <span className={visible ? "" : "invisible"}>
      {t("please_provide_valid_phone_number")}
    </span>
  );
  return (
    <div>
      <div className="flex items-center">
        <div className="w-full">
          <PhoneInput
            defaultCountry="US"
            value={value}
            onChange={(value) => setValue(value)}
            className={
              "bp4-input " +
              (value && (hasError || !validPhoneNumber)
                ? "bp4-intent-danger"
                : "")
            }
            onBlur={(event) => {
              updateResponse(value);
            }}
          />
        </div>
      </div>
      {value && !validPhoneNumber && (
        <div className="bp4-form-helper-text">
          <Helper visible={value ? !validPhoneNumber : ""} />
        </div>
      )}
    </div>
  );
};

PhoneNumberPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
  validPhoneNumber: PropTypes.bool,
};
