import React, { useState, useContext, useEffect } from "react";
import { keyBy, isObject } from "lodash";
import PropTypes from "prop-types";
import { promptResponse as createPromptResponse } from "../state/models";
import * as PromptTypes from "../../helpers/prompt_types";
import { HORIZONTAL } from "../../helpers/variants";
import { connect } from "react-redux";
import { postPromptResponse } from "../state/actions";
import { PanelComponentContext } from "../../AdminApp/helpers/contexts";
import { SingleSelectPrompt } from "./SingleSelectPrompt";
import { NumberPrompt } from "./NumberPrompt";
import { PhoneNumberPrompt } from "./PhoneNumberPrompt";
import { EmailPrompt } from "./EmailPrompt";
import { SelectManyPrompt } from "./SelectManyPrompt";
import { BooleanPrompt } from "./BooleanPrompt";
import { ShortTextPrompt } from "./ShortTextPrompt";
import { LongTextPrompt } from "./LongTextPrompt";
import { DatePrompt } from "./DatePrompt";
import { TimePrompt } from "./TimePrompt";
import { UrlParamsPrompt } from "./UrlParamsPrompt";
import { Icon, Intent } from "@blueprintjs/core";
import { isValidPhoneNumber } from "react-phone-number-input";
import cx from "classnames";
//import marky from "@npmcorp/marky-markdown";

import { useTranslation } from "react-i18next";

export const PromptResponse = props => {
  const { t } = useTranslation();
  const {
    prompt: {
      value,
      text,
      id,
      prompt_type,
      min_value,
      max_value,
      is_required,
      display_variant,
      style_class
    },
    surveyConditions = [],
    fulfilledConditions,
    promptOptions,
    handleUpdateResponseValue,
    hasError,
    shortName,
    noCollection
  } = props;

  const [promptResponse, setPromptResponseValue] = useState(
    createPromptResponse({
      promptId: id
    })
  );

  const [hidden, setHidden] = useState(true);

  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validEmail = regEmail.test(promptResponse.value) ? true : false;
  const validPhoneNumber =
    promptResponse.value !== undefined
      ? isValidPhoneNumber(promptResponse.value)
        ? true
        : false
      : false;

  const preview = useContext(PanelComponentContext);

  const updateResponseValue = (value) => {
    if (preview || noCollection) return false;
    const updatedPromptResponse = isObject(value)
      ? { ...promptResponse, ...value }
      : { ...promptResponse, value };
    setPromptResponseValue(updatedPromptResponse);
    return handleUpdateResponseValue(shortName, updatedPromptResponse);
  };

  const updateDateResponseValue = (value) =>
    updateResponseValue(value ? value.toISOString().split("T")[0] : "");

  useEffect(() => {
    const promptSurveyConditions = surveyConditions.filter(
      (condition) => condition.subject_id === id
    );
    if (promptSurveyConditions.length) {
      if (
        fulfilledConditions.some(
          (condition) => condition.subject_id === id && condition.selected
        )
      ) {
        return setHidden(false);
      }
      return setHidden(true);
    }
    return setHidden(false);
  }, [fulfilledConditions]);

  return (
    <div
      className={
        prompt_type === PromptTypes.URL_PARAMS
          ? ""
          : cx(
            `bp4-form-group text-lg w-full ${is_required &&
            (promptResponse.value === "" ||
              promptResponse.value === undefined)}`,
            {
              hidden,
              horizontal: display_variant === HORIZONTAL
            },
            style_class
          )
      }
    >
      {value &&
        prompt_type != PromptTypes.URL_PARAMS &&
        prompt_type != PromptTypes.BOOLEAN && (
          <span id="label" className="text-lg">
            <React.Fragment>
              <span dangerouslySetInnerHTML={{ __html: value }} />
              {is_required && (
                <Icon
                  icon="flag"
                  className={"ml-2 " + (!hasError ? "text-grey" : "")}
                  iconSize="20"
                  intent={hasError ? Intent.DANGER : Intent.NONE}
                />
              )}{" "}
              {is_required && (
                <span className="bp4-text-muted">({t("required")})</span>
              )}
              {(prompt_type === PromptTypes.EMAIL ||
                prompt_type === PromptTypes.PHONE_NUMBER) &&
                promptResponse.value !== undefined &&
                promptResponse.value !== "" && (
                  <Icon
                    className="ml-1 mt-1"
                    icon={validEmail || validPhoneNumber ? "tick" : "cross"}
                    iconSize="20"
                    intent={
                      validEmail || validPhoneNumber
                        ? Intent.SUCCESS
                        : Intent.DANGER
                    }
                  />
                )}
            </React.Fragment>
          </span>
        )}

      <div className="bp4-form-content">
        <div className="bp4-input-group bp4-fill">
          {prompt_type === PromptTypes.CONTENT && (
            <div
              className="promptType__content"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {prompt_type === PromptTypes.SINGLE_SELECT && (
            <SingleSelectPrompt
              promptOptions={promptOptions}
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              displayVariant={display_variant}
              noCollection={noCollection}
            />
          )}

          {prompt_type === PromptTypes.MULTI_SELECT && (
            <SelectManyPrompt
              promptOptions={promptOptions}
              updateResponse={updateResponseValue}
              displayVariant={display_variant}
            />
          )}

          {prompt_type === PromptTypes.BOOLEAN && (
            <BooleanPrompt
              updateResponse={updateResponseValue}
              hasError={hasError}
            />
          )}

          {prompt_type === PromptTypes.DATE && (
            <DatePrompt
              updateResponse={updateDateResponseValue}
              minValue={min_value}
              maxValue={max_value}
              hasError={hasError}
            />
          )}

          {prompt_type === PromptTypes.TIME && (
            <TimePrompt
              updateResponse={updateResponseValue}
              hasError={hasError}
            />
          )}

          {prompt_type === PromptTypes.SHORT_TEXT && (
            <ShortTextPrompt
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              hasError={hasError}
            />
          )}
          {prompt_type === PromptTypes.LONG_TEXT && (
            <LongTextPrompt
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              hasError={hasError}
            />
          )}
          {prompt_type === PromptTypes.URL_PARAMS && (
            <UrlParamsPrompt
              value={value}
              updateResponse={updateResponseValue}
            />
          )}

          {prompt_type === PromptTypes.PHONE_NUMBER && (
            <PhoneNumberPrompt
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              hasError={hasError}
              validPhoneNumber={validPhoneNumber}
            />
          )}

          {prompt_type === PromptTypes.EMAIL && (
            <EmailPrompt
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              hasError={hasError}
              validEmail={validEmail}
            />
          )}

          {prompt_type === PromptTypes.NUMBER && (
            <NumberPrompt
              value={value}
              promptResponse={promptResponse}
              updateResponse={updateResponseValue}
              minValue={min_value}
              maxValue={max_value}
              hasError={hasError}
            />
          )}

          {value && prompt_type === PromptTypes.BOOLEAN && (
            <span
              className="text-lg"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PromptResponse.propTypes = {
  prompt: PropTypes.object,
  promptOptions: PropTypes.array,
  handleUpdateResponseValue: PropTypes.func,
  surveyConditions: PropTypes.array,
  fulfilledConditions: PropTypes.array,
  hasError: PropTypes.bool,
  shortName: PropTypes.string,
  noCollection: PropTypes.bool
};

export const PromptResponseContainer = connect(null, dispatch => ({
  handleUpdateResponseValue: (shortName, promptResponse) => {
    dispatch((dispatch, getState) => {
      const { surveyResponse, promptResponses } = getState();
      const oldPromptResponse = keyBy(promptResponses, "prompt_id")[
        promptResponse.prompt_id
      ];
      if (surveyResponse !== null) {
        if (oldPromptResponse) promptResponse.id = oldPromptResponse.id;
        dispatch(
          postPromptResponse(shortName, {
            prompt_response: {
              ...promptResponse,
              survey_response_id: surveyResponse.id
            }
          })
        );
      }
    });
  }
}))(PromptResponse);
