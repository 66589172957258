import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputGroup, FormGroup, Icon, Intent } from "@blueprintjs/core";
import { useDebounce } from "../../helpers/hooks/useDebounce";

import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";

export const EmailPrompt = ({ updateResponse, hasError, validEmail }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const Helper = ({ visible }) => (
    <span className={visible ? "" : "invisible"}>
      {t("please_provide_valid_email")}
    </span>
  );
  return (
    <div>
      <div className="flex items-center">
        <div className="w-full">
          <InputGroup
            type="email"
            name="email"
            className="bp4-fill"
            id="email_input"
            onChange={(event) => setInputValue(event.target.value)}
            onBlur={(event) => {
              updateResponse(event.target.value);
            }}
            intent={
              inputValue && (hasError || !validEmail)
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        </div>
      </div>
      {inputValue && !validEmail && (
        <div className="bp4-form-helper-text">
          <Helper visible={inputValue ? !validEmail : ""} />
        </div>
      )}
    </div>
  );
};

EmailPrompt.propTypes = {
  updateResponse: PropTypes.func,
  hasError: PropTypes.bool,
  validEmail: PropTypes.bool,
};
